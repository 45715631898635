<template>
  <div ref="swiperContainer">
    <swiper id="img-swiper" class="swiper" ref="mySwiper" :options="swiperOptions" @clickSlide="showImage">
      <!--  isDuplicate is true when current slide is a duplicate slide (when loop mode enabled)  -->
      <swiper-slide
        class="swiper-slide"
        v-for="(fileName, index) in imageNames"
        :key="'imgSwiper-slide ' + index"
      >
        <img :src="imgBaseURL + fileName" :alt="fileName"/>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import Viewer from 'viewerjs/dist/viewer.min'

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'img-swiper',
  title: 'swiper',
  props: ['imageNames'],
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 1,
        //loop true: slides begin w/ index 0 when last slide is reached.
        // Causes duplicates to be rendered left & right if only one slide present
        loop: false,
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      currentSlide: 0,
      Viewer: null
    }
  },
  mounted () {
    this.Viewer = new Viewer(this.$refs.swiperContainer)
  },
  methods: {
    showImage (e) {
      this.Viewer.view(e)
    },
  },
  computed: {
    imgBaseURL: () => {
      return process.env.VUE_APP_API_BASE_URL + '/img/'
    }
  },
}
</script>

<style lang="scss">

.swiper-container {
  width: 500px;

  .swiper-wrapper {
    display: flex;
    align-items: center !important;
  }

  .swiper-pagination {
    .swiper-pagination-bullet-active {
      background-color: $color-accent;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    color: $color-accent;

    &:hover {
      color: $color-accent-hover;
    }
  }

  .swiper-wrapper {
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      border-radius: 1.5rem !important;
      -webkit-border-radius: 1.5rem !important;
      background: #fff;
      //Also check #item-images on _item page & img tag down below for height
      min-height: 256px;

      //Center slide text vertically
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        //!To adjust slider height change max-height for content!
        max-height: 468px;
      }
    }
  }
}
</style>
