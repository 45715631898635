<template>
  <div id="search-gallery">
    <b-carousel
      id="image-carousel"
      ref="carousel"
      controls
      :indicators="indicators"
      v-model="currentSlide"
      :interval="0"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
        class="test"
        v-for="fileName in imgFiles"
        :key="fileName"
        :style="'background-image: url(' + imgBaseURL + fileName + ')'"
      >
        image.file_name
      </b-carousel-slide>
      <div v-for="(fileName,index) in imgFiles" :key="index">
        <img class="viewer-img" :src="imgBaseURL + fileName" style="display: none" alt="">
      </div>
    </b-carousel>

    <div v-if="imgFiles.length > 4" id="thumb-carousel">
      <div id="thumb-inner">
        <div class="thumb-container" v-for="(fileName,index) in imgFiles" :key="index">
          <img :src="imgBaseURL + fileName"
               :height="thumbSettings.height + 'px'"
               v-on:click="scrollTo(index)"
               v-on:load="saveWidth($event)"
               alt=""
          >
        </div>
      </div>
      <a id="thumb-control-left" v-on:click="moveThumbs(1)">
        <font-awesome-icon icon="arrow-circle-left"></font-awesome-icon>
      </a>
      <a id="thumb-control-right" v-on:click="moveThumbs(-1)">
        <font-awesome-icon icon="arrow-circle-right"></font-awesome-icon>
      </a>
    </div>
  </div>
</template>

<script>
  import 'viewerjs/dist/viewer.css'
  import Viewer from 'viewerjs/dist/viewer.min'

  export default {
    name: 'gallery',
    props: {
      imgFiles: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        currentSlide: 0,
        currentThumb: 0,
        thumbInnerWidth: 0,
        thumbInnerStep: 0,
        thumbOffset: 0,
        thumbSettings: {
          height: 80,
          margin: 10
        },
        sliding: false
      }
    },
    watch: {
      thumbInnerWidth: function () {
        this.thumbInnerStep = 2 * Math.floor(this.thumbInnerWidth / this.imgFiles.length)
      },
      thumbOffset: function (newVal, oldVal) {
        if (newVal > oldVal) {
          document.getElementById('thumb-control-right').style.display = 'flex'
        } else {
          document.getElementById('thumb-control-left').style.display = 'flex'
        }
      }
    },
    computed: {
      imgBaseURL: () => {
        return process.env.VUE_APP_API_BASE_URL + '/img/'
      },
      indicators: function () {
        if(this.imgFiles.length > 1) return true
      }
    },
    mounted () {
      const viewer = new Viewer(this.$el.querySelector('#image-carousel'), { button: false })
      Array.from(document.getElementsByClassName('carousel-item')).forEach((citem) => {
          citem.addEventListener('click', (e) =>
            this.showImage(e, viewer))
        }
      )
    },
    methods: {
      onSlideStart () {
        this.sliding = true
      },
      onSlideEnd () {
        this.sliding = false
      },
      scrollTo (index) {
        this.currentSlide = index
      },
      showImage (e, viewer) {
        viewer.view(this.currentSlide)
      },
      moveThumbs (dir) {
        const thumbInner = document.getElementById('thumb-inner')
        const thumbBarWidth = thumbInner.offsetWidth
        const stepSize = this.thumbInnerStep * dir

        if ((this.thumbInnerWidth - thumbBarWidth) + stepSize >= Math.abs(this.thumbOffset) && this.thumbOffset + stepSize < 0) {
          this.thumbOffset += stepSize
        } else if (dir === -1) { //right
          this.thumbOffset = (this.thumbInnerWidth - thumbBarWidth) * dir
          document.getElementById('thumb-control-right').style.display = 'none'
        } else { //left
          this.thumbOffset = 0
          document.getElementById('thumb-control-left').style.display = 'none'
        }
        thumbInner.style.transform = 'translateX(' + this.thumbOffset + 'px)'
      },
      saveWidth (event) {
        this.thumbInnerWidth += event.target.parentElement.offsetWidth
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.calcGallery)
    }
  }
</script>
<style lang="scss" type="scss">
  #search-gallery {
    align-self: center;
    overflow: hidden;

    @media (max-width: $bp-large) {
      width: 50%;
    }
    @media (max-width: $bp-small) {
      width: 100%;
    }

    #image-carousel {
      .carousel-inner {
        :hover {
          cursor: zoom-in;
        }

        .carousel-item {
          height: 320px;
          background: center no-repeat;
          background-size: contain;
          outline: none;
        }
      }

      .carousel-caption {
        display: none;
      }

      .carousel-indicators {
        :hover {
          cursor: pointer;
        }
      }
    }

    #thumb-carousel {
      position: relative;
      margin-top: 10px;

      #thumb-inner {
        display: flex;
        transform: translateX(0px);
        transition: 400ms ease-out;
      }

      .thumb-container {
        display: inline-block;
        padding: 0 5px 0 5px;

        &:first-child {
          padding: 0 5px 0 0;
        }

        &:last-child {
          padding: 0 0 0 5px;
        }

        img {
          border: 2px solid white;
          cursor: pointer;
        }

        img:hover {
          border: 2px solid $color-accent-hover;
        }
      }

      > a {
        position: absolute;
        display: flex;
        width: 10%;
        top: 0;
        bottom: 0;
        font-size: 1.5em;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      #thumb-control-right {
        right: 0;
      }

      #thumb-control-left {
        display: none;
      }
    }
  }
</style>
