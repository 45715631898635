<template>
  <div id="distribution-map">
    <l-map
      id="l-map"
      ref="dist_map"
      :maxBounds="maxBounds"
      :maxZoom="15"
      :zoom="1"
      style="height: 100%"
    >
      <l-tile-layer :clickable="true" :url="url"></l-tile-layer>
    </l-map>
  </div>
</template>

<script>
// TODO: With some MultiPolygons map won't render / display geoJson. E.g. "Cryptophis boschmai"
// -> Right Hand rule? Eventually use geojson-rewind.

import { LMap, LTileLayer } from 'vue2-leaflet'

const accessToken = 'pk.eyJ1IjoiMXRhc3R5cmFpbmJvdyIsImEiOiJja2IyaGlnZHcwYm10MnNuNzlvczBqbXcyIn0.QWoxmiQECk4kWU1udICLNg'

export default {
  name: 'DistributionMap',
  components: {
    LMap,
    LTileLayer
  },
  props: {
    geometry: Object
  },
  data () {
    return {
      attribution: '© <a href=\"https://www.mapbox.com/feedback/\">Mapbox</a> © <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors',
      url: 'https://api.mapbox.com/styles/v1/1tastyrainbow/ckb2hqunx17471jnp8e631nay/tiles/512/{z}/{x}/{y}@2x?access_token=' + accessToken,
      map: null,
      geoJSON: null
    }
  },
  computed: {
    maxBounds () {
      const cSouthWest = L.latLng(-180, -90)
      const cNortEast = L.latLng(180, 90)
      return L.latLngBounds(cSouthWest, cNortEast).pad(0.85)
    }
  },
  mounted () {
    this.map = this.$refs.dist_map.mapObject
    const mapboxTiles = L.tileLayer(this.url, {
      attribution: this.attribution,
      tileSize: 512,
      zoomOffset: -1
    })
    this.map.addLayer(mapboxTiles)
    this.showGeoData()

    //Watch size changes - if map size is changed, re-center the displayed geoJSON
    new ResizeObserver(this.fitBounds).observe(this.$refs.dist_map.$el)
  },
  methods: {
    showGeoData () {
      const style = {
        stroke: true,
        opacity: 0.5,
        fill: true,
        fillOpacity: 0.5,
        fillColor: 'pink'
      }

      this.geoJSON = L.geoJson(this.geometry, {
        clickable: true,
        style: style
      }).bindPopup(function (layer) {
        return layer.feature.properties.binomial
      }).addTo(this.map)
    },
    //If the map's size is changed due to the image gallery, new bounds have to set to center the geoJSON
    fitBounds () {
      if (this.geoJSON) {
        this.map.invalidateSize()
        this.map.fitBounds(this.geoJSON.getBounds().pad(0.10))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#distribution-map {
  min-height: 250px;
}
</style>
