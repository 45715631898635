<template>
  <b-container id="search-detail">
    <search-breadcrumb></search-breadcrumb>
    <spinner-indeterminate v-if="isLoading"></spinner-indeterminate>
    <div class="detail-head">
      <h3 v-if="!isLoading">{{ title }}</h3>
    </div>
    <div v-if="!isLoading">
      <div class="detail-media">
        <swiper v-if="imgFileNames && imgFileNames.length > 0" :image-names="imgFileNames"></swiper>
        <distribution-map v-if="spatial" v-bind:geometry="spatial.geometry">
        </distribution-map>
      </div>
      <taxa-table v-bind:items="[taxa]"></taxa-table>
      <reference-table v-bind:items="bibliography"></reference-table>
    </div>
  </b-container>
</template>

<script>
import Gallery from '../base/Gallery'
import TaxaTable from '../TaxaTable'
import Swiper from '@/components/base/Swiper'
import ReferenceTable from '../ReferenceTable'
import DistributionMap from '../base/DistributionMap'
import SpinnerIndeterminate from '../base/SpinnerIndeterminate'
import SearchBreadcrumb from '@/components/search/SearchBreadcrumb'

import { isEmpty } from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'search-detail',
  components: {
    SearchBreadcrumb,
    SpinnerIndeterminate,
    Gallery,
    TaxaTable,
    ReferenceTable,
    DistributionMap,
    Swiper
  },
  data () {
    return {
      spatial: null
    }
  },
  mounted () {
    this.getTaxa(this.$route.fullPath)
    let genus = this.$route.query.genus
    let species = this.$route.query.species

    // do not save spatial data in vuex as its too slow
    this.getSpatial({
      genus,
      species
    }).then((data) => {
      this.spatial = data
    })

    this.getImages({
      genus,
      species
    })
  },
  computed: {
    ...mapState('species', [
      'taxa',
      'bibliography',
      'imgFileNames',
      'isLoading',
      'title'
    ])
  },
  methods: {
    ...mapActions('species', ['getTaxa', 'getSpatial', 'getBibliography', 'getImages'])
  },
  watch: {
    taxa: function () {
      if (!isEmpty(this.taxa) && this.taxa.referenceNumbers.length > 0) {
        let refIds = this.taxa.referenceNumbers.replaceAll('\n', ',')
        this.getBibliography(refIds)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('species/reset')
    next()
  }
}
</script>

<style lang="scss" type="scss">
#search-detail {
  .detail-media {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    max-width: 1050px;

    #search-gallery,
    #distribution-map {
      display: inline-block;
      position: relative;
      flex-grow: 1;
    }

    #search-gallery {
      margin-right: 5px;
    }

    #distribution-map {
      margin-left: 5px
    }
  }

  .detail-head {
    width: 100%;
    display: inline-block;

    @media (max-width: $bp-small) {
      margin: 0;
    }
  }
}
</style>
