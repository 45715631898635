<template>
    <b-table id="taxa-table" :stacked="true" :fields="fields" :items="items"></b-table>
</template>

<script>
  export default {
    name: 'taxa-table',
    props: {
      items: {}
    },
    data () {
      return {
        fields: [
          {
            key: 'taxa',
            label: 'Higher Taxa'
          },
          {
            key: 'subspecies',
            label: 'Subspecies'
          },
          {
            key: 'common_names',
            label: 'Common Names'
          },
          {
            key: 'species_author_year',
            label: 'Synonym'
          },
          {
            key: 'distribution'
          },
          {
            key: 'reproduction'
          },
          {
            key: 'types'
          },
          {
            key: 'diagnosis'
          },
          {
            key: 'comments'
          },
          {
            key: 'etymology'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" type="scss">
  #taxa-table {
    white-space: pre-line;
    margin-top: 25px;

    td {
      grid-template-columns: 20% auto;
    }
    ::before {
      text-align: left;
    }
  }
</style>
