<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <b-container :fluid="true">
    <div id="references">
      <h4>References</h4>

        <!-- User Interface controls -->
        <b-row>
          <b-col md="6" class="my-1">
            <b-form-group horizontal label="Filter" class="mb-0">
              <b-input-group>
                <b-form-input v-model="filter" placeholder="Type to filter bibliography" />
                <b-input-group-append>
                  <b-btn :disabled="!filter" @click="filter = ''">Clear</b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="3" class="my-1">
            <b-form-group horizontal label="Per page" class="mb-0">
              <b-form-select :options="pageOptions" v-model="perPage" />
            </b-form-group>
          </b-col>
        </b-row>
      <b-table id="ref-table"
               small
               responsive="true"
               :current-page="currentPage"
               :per-page="perPage"
               :sort-by.sync="sortBy"
               :filter="filter"
               :fields="fields"
               :items="items"
               @filtered="onFiltered">
        <template v-slot:cell(source)="data">
          {{data.item.source}}
          <a class="source-paperclip" v-if="data.item.link" :href="data.item.link" target="_blank"><font-awesome-icon icon="paperclip"></font-awesome-icon></a>
        </template>
      </b-table>
      <b-row v-if="this.items">
        <b-col md="12" class="my-1">
          <b-pagination :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" class="my-0" />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
  export default {
    name: 'reference-table',
    props: [
      'items'
    ],
    data () {
      return {
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
        pageOptions: [10, 15, 25, 50],
        sortBy: 'author',
        fields: [
          {
            key: 'author',
            sortable: true
          },
          {
            key: 'year',
            sortable: true
          },
          {
            key: 'titel',
            sortable: true
          },
          {
            key: 'source'
          }
        ],
        filter: null
      }
    },
    methods: {
      onFiltered (filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
  }
</script>

<style lang="scss" type="scss">
  #references {
    margin-top: 50px;

    h4 {
      margin-bottom: 20px;
    }

    .table-options {
      margin-bottom: 15px;

      .row {
        flex-direction: column;

        .my-1 {
          align-self: flex-end;
        }
      }
    }
    .source-paperclip {
      float: right;
    }
  }
</style>
